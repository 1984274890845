/* HOVER STATES FOR LINKS */

@mixin link-color($color, $hover-lighter: true, $percentage: 20%) {
  color: $color;
  &:hover {
    @if ($hover-lighter) {
      color: lighten($color, $percentage);
    }
    @else {
      color: darken($color, $percentage);
    }
  }
}

@mixin link-background($color, $hover-lighter: true, $percentage: 10%) {
  background-color: $color;
  &:hover {
    @if ($hover-lighter) {
      background-color: lighten($color, $percentage);
    }
    @else {
      background-color: darken($color, $percentage);
    }
  }
}

@mixin link-opacity($hover-opaque: false, $opacity: 0.75) {
  @if ($hover-opaque) {
    opacity: $opacity;
    &:hover {
      opacity: 1;
    }
  }
  @else {
    opacity: 1;
    &:hover {
      opacity: $opacity;
    }
  }
}


/* SHAPES */

@mixin circle($size, $color: null) {
  width: $size;
  height: $size;
  border-radius: 50%;
  @if ($color) {
    background-color: $color;
  }
}

@mixin triangle($direction: down, $size: 20px, $color: black) {
  width: 0px; 
  height: 0px;
  border: $size solid transparent;
  @if ($direction == left) {
    border-right-color: $color;
  }
  @else if ($direction == right) {
    border-left-color: $color;
  }
  @else if ($direction == up) {
    border-bottom-color: $color;
  }
  @else if ($direction == down) {
    border-top-color: $color;
  }
}


/* LAYOUT */

@mixin inline-block($vertical-align: top) {
  display: inline-block;
  vertical-align: $vertical-align;
}

@mixin spacing($spacing, $text-align: left) {
  @if ($text-align == left) {
    margin-right: -$spacing;
  }
  @else {
    margin-left: -$spacing;
  }
  margin-bottom: -$spacing;
  & > * {
    @if ($text-align == left) {
      margin-right: $spacing;
    }
    @else {
      margin-left: $spacing;
    }
    margin-bottom: $spacing;
  }
}

@mixin position($x, $y) {
  position: absolute;
  @if ($x == center) {
    left: 0px;
    right: 0px;
  }
  @else if ($x == left) {
    left: 0px;
  }
  @else if ($x == right) {
    right: 0px;
  }
  @else {
    left: $x;
  }
  @if ($y == center) {
    top: 0px;
    bottom: 0px;
  }
  @else if ($y == top) {
    top: 0px;
  }
  @else if ($y == bottom) {
    bottom: 0px;
  }
  @else {
    top: $y;
  }
  @if ($x == center or $y == center) {
    margin: auto;
  }
}

@mixin halign-middle {
  left: 50%;
  transform: translate(-50%, 0);
}

@mixin valign-middle {
  top: 50%;
  transform: translate(0, -50%);
}

@mixin ratio($width, $height) {
  &::before {
    content: "";
    display: block;
    height: 0px;
    padding-bottom: ($height / $width * 100) + 0%;
  }
}


/* MISC */

@mixin ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin scrollable-x {
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

@mixin scrollable-y {
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}