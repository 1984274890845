/* RESET */

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
  position: relative;
}

body {
  line-height: 1;
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0px;
  padding: 0px;
}