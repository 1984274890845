/* CONSTANTS */

$MEDIA-QUERY-SMALL-TABLET: "only screen and (max-width: 750px)";
$MEDIA-QUERY-MOBILE: "only screen and (max-width: 500px)";

$WEIGHT-LIGHT: 300;
$WEIGHT-NORMAL: 400;
$WEIGHT-SEMIBOLD: 600;
$WEIGHT-BOLD: 700;

$COLOR-LINK: blue;
$COLOR-HEADER-BACKGROUND: #999;

$LINE-HEIGHT: 1.4;
$CONTENT-PADDING: 35px;
$CONTENT-PADDING-MOBILE: 20px;


/* CUSTOM RESETS */

body {
  background-color: #eee;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: black;
}

a {
  @include link-color($COLOR-LINK);
  cursor: pointer;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

input, textarea, button, select, a {
  outline: none;
}

input[type="text"], input[type="password"], input[type="email"], input[type="number"], input[type="tel"], textarea {
  height: 36px;
  padding: 0px;
  padding-left: 5px;
  border: 1px solid #ddd;
  border-radius: 0px;
  background-color: white;
  font-size: 14px;
  color: black;
}

textarea {
  height: 100px;
  padding-top: 5px;
  line-height: $LINE-HEIGHT;
  resize: vertical;
}

button {
  display: inline-block;
  vertical-align: top;
  height: 36px;
  padding: 0px 18px;
  border: none;
  border-radius: 0px;
  background-color: #bbb;
  font-size: 14px;
  color: black;
  text-align: center;
  @include ellipsis;
  cursor: pointer;
  &:hover {
    background-color: #c4c4c4;
  }
  &[disabled] {
    background-color: #e0e0e0;
    color: #aaa;
  }
}

h1, h2, h3, h4, h5, h6, p {
  line-height: $LINE-HEIGHT;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 10px;
}

h1 {
  font-size: 32px;
  font-weight: $WEIGHT-LIGHT;
}

h2 {
  font-size: 28px;
  letter-spacing: -1px;
}

h3 {
  font-size: 24px;
  letter-spacing: -1px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

p {
  p + & {
    margin-top: 10px;
  }
}

.rich-text {
  ul {
    padding-left: 20px;

    ul, li + li {
      margin-top: 10px;
    }
  }
}


/* MAIN CONTAINERS */

.wrapper {
  padding: 20px;
}

.container {
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
  background-color: white;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.12);
}

header {
  $height: 100px;
  height: $height;
  padding: 0px $CONTENT-PADDING;
  background-color: $COLOR-HEADER-BACKGROUND;
  color: white;
  text-align: center;
  a.logo {
    display: inline-block;
    max-width: 400px;
    max-height: 70px;
    @include valign-middle;
    img {
      max-height: 100%;
    }
  }
  h1 {
    line-height: $height;
  }
  .locale {
    $height: 27px;
    position: absolute;
    top: 10px;
    right: 10px;
    a {
      display: block;
      height: $height;
      padding: 0px 10px;
      font-size: 13px;
      text-align: center;
      line-height: $height - 3px;
      white-space: nowrap;
    }
    & > a {
      $color: white;
      padding-right: 7px;
      border: 1px solid $color;
      color: $color;
      &:hover {
        background-color: rgba(white, 0.1);
      }
    }
    & > ul.menu {
      display: none;
      position: absolute;
      top: $height;
      right: 0px;
      border: 1px solid black;
      background-color: white;
      & > li {
        display: block;
        & > a {
          color: black;
          text-align: right;
          &:hover {
            background-color: rgba(black, 0.1);
          }
        }
      }
    }
    @media #{$MEDIA-QUERY-MOBILE} {
      $height: 22px;
      top: 5px;
      right: 5px;
      & > a {
        height: $height;
        padding: 0px 7px;
        font-size: 12px;
        line-height: $height - 3px;
      }
      & > ul.menu {
        top: $height;
      }
    }
  }
  @media #{$MEDIA-QUERY-MOBILE} {
    padding-left: $CONTENT-PADDING-MOBILE;
    padding-right: $CONTENT-PADDING-MOBILE;
  }
}

.content {
  min-height: calc(100vh - 230px);
  padding: $CONTENT-PADDING;
  padding-bottom: $CONTENT-PADDING + 5px;
  background-color: white;
  @media #{$MEDIA-QUERY-MOBILE} {
    padding: $CONTENT-PADDING-MOBILE;
    padding-bottom: $CONTENT-PADDING-MOBILE + 5px;
  }
}

footer {
  padding: $CONTENT-PADDING;
  background-color: $COLOR-HEADER-BACKGROUND;
  ul.links {
    font-size: 12px;
    text-align: center;
    & > li {
      @include inline-block;
      margin: 0px 10px;
      & > a {
        display: block;
        color: rgba(white, 0.6);
        &:hover {
          color: rgba(white, 1);
        }
      }
    }
  }
  @media #{$MEDIA-QUERY-MOBILE} {
    padding: $CONTENT-PADDING-MOBILE;
  }
}


/* COMMON ELEMENTS */

.gap-small {
  height: 10px;
}

.gap {
  height: 20px;
}

.gap-large {
  height: 30px;
}

.well {
  padding: 15px 20px;
  background-color: #eee;
}

ul.errors {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f66;
  color: white;
  line-height: $LINE-HEIGHT;
  & > li {
    display: block;
    & + li {
      margin-top: 10px;
    }
  }
}

ul.payment-methods {
  @include spacing(5px);
  & > li {
    @include inline-block;
    & > img {
      width: 48px;
      height: 30px;
      border-radius: 3px;
    }
  }
}

table.form {
  width: 700px;
  max-width: 100%;
  tr {
    & > td {
      &:first-child {
        width: 130px;
        padding-right: 15px;
        text-align: right;
      }
      &:last-child {
        input, select {
          display: block;
          width: 100%;
        }
      }
    }
    & + tr {
      td {
        padding-top: 15px;
      }
    }
  }
  @media #{$MEDIA-QUERY-MOBILE} {
    tr {
      & > td {
        &:first-child {
          width: 90px;
        }
      }
    }
  }
}


/* DEVELOPER PAGE */

ul.applications {
  @include spacing(10px);
  & > li {
    @include inline-block;
    & > a {
      display: block;
      width: 300px;
      height: 325px;
      padding: 15px;
      border: 1px solid #ddd;
      color: black;
      img {
        max-height: 200px;
        margin: 0 auto;
      }
      .description {
      }
      &:hover {
        border-color: #eee;
        background-color: #eee;
      }
    }
  }
}


/* APPLICATION PAGE */

section.application {
  display: table;
  table-layout: fixed;
  width: 100%;
  & > .left, & > .right {
    display: table-cell;
    vertical-align: top;
  }
  & > .left {
    width: 300px;
    padding-right: 30px;
  }
  @media #{$MEDIA-QUERY-SMALL-TABLET} {
    display: block;
    & > .left, & > .right {
      display: block;
      width: 100%;
    }
    & > .left {
      margin-bottom: 20px;
      padding-right: 0px;
    }
  }
}

ul.license-types {
  padding: 5px 0px;
  line-height: $LINE-HEIGHT;
  & > li {
    display: block;
    font-size: 16px;
    & + li {
      margin-top: 10px;
    }
    label {
      & > span {
        margin-left: 3px;
      }
      .price {
        font-weight: bold;
        color: #007f00;
      }
    }
  }
}


/* CART PAGE */

table.cart {
  width: 100%;
  tr {
    th, td {
      padding: 10px;
      border: 1px solid #bbb;
      &:nth-child(2) {
        width: 120px;
      }
      &:nth-child(3) {
        width: 120px;
      }
      &:last-child {
        width: 120px;
      }
    }
    th {
      background-color: #ddd;
    }
    td {
      &:not(:first-child) {
        text-align: center;
      }
      input {
        width: 100%;
      }
    }
  }
}