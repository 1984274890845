/* GRID */

.grid {
  & > .unit {
    display: inline-block;
    vertical-align: top;
  }
}

@for $n from 1 through 10 {
  @for $m from 1 through max($n - 1, 1) {
    .u-#{$m}-of-#{$n} {
      width: $m * (100% / $n);
    }
  }
}

@for $n from 1 through 300 {
  @if $n < 20 or round($n / 5) == ($n / 5) {
    $padding: round($n / 2) + 0px;
    .spacing-#{$n} {
      margin: 0px -#{$padding};
      & > .unit {
        padding: 0px #{$padding};
      }
    }
  }
}